@import "~@parkable/react/build/index.css";

html,
body {
  padding: 0;
  margin: 0;
  font-family: GT Eesti Display, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Rapi-doc plus ::part(#) to modify existing CSS */

rapi-doc::part(section-navbar) {
  padding-top: 55px;
}

rapi-doc::part(section-servers) {
  margin-top: 30px;
}

rapi-doc::part(section-auth) {
  margin-top: 30px;
}

rapi-doc::part(section-operation-summary) {
  font-size: 32px;
  margin-top: 0;
}

rapi-doc::part(section-operation-tag) {
  margin-top: -10px;
}

/*To overwrite pre-set styling*/
rapi-doc {
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --white: #fff;
  /*--font-size-small: 14px;*/
  --font-size-mono: 16px;
  /*--font-size-regular: 18px;*/
}
